<template>
    <div class="card p-mt-3 p-d-flex p-flex-column p-px-0 p-py-0 allHeight">
        <div class="p-d-flex p-flex-column p-px-0 p-py-0 allHeight customBGColor">
            <div class="p-col-12 p-lg-12 p-mb-lg-0 p-pb-0 customRelatyve">
                <label class="p-text-normal" for="type">Наименование*</label>
                <InputText id="type" style="width: 100%" v-model="v$.periodTitle.$model" placeholder="Введите наименование" :class="v$.periodTitle.$invalid && submitted ? 'p-error' : ''" class="customWidth" />
                <small v-show="submitted" v-for="error in showErrors(v$.periodTitle)" :key="error.$uid" class="p-error p-errorPosition p-errorPositionAdvanced">{{ error.$message }}</small>
            </div>
            <div class="p-col-12 p-lg-12 p-mb-lg-0 p-pl-0 p-d-flex p-jc-start">
                <div class="p-col-4 p-lg-2 p-mb-lg-0 p-py-0">
                    <label class="p-text-normal" for="type">Повторяемость*</label>
                    <div class="p-d-flex p-ai-center customWidth">
                        <div class="p-col-12 p-px-0 p-py-0 customRelatyve">
                            <Dropdown
                                id="type"
                                :disabled='!!periodicityId'
                                class="p-col-12 p-px-0 p-py-0"
                                v-model="v$.rangeType.$model"
                                :options="typesValues"
                                optionLabel="name"
                                placeholder="Выберите повторяемость"
                                :class="v$.rangeType.name.$invalid && submitted ? 'p-error' : ''"
                            />
                        </div>
                        <small v-show="submitted" v-for="error in showErrors(v$.rangeType)" :key="error.$uid" class="p-error p-errorPosition">{{ error.$message }}</small>
                        <label class="p-text-normal p-mx-2 p-pb-0" for="mask">каждый(ую)*</label>
                        <div class="p-col-12 p-px-0 p-py-0 customRelatyve inputCustomWidth">
                            <InputNumber
                                id="mask"
                                style="max-width: 100%"
                                :disabled='!!periodicityId'
                                :min="1"
                                :max="365"
                                v-model="v$.rangeValue.$model"
                                :class="v$.rangeValue.$invalid && submitted ? 'p-error' : ''"
                                type="text"
                            > </InputNumber>
                        </div>

                        <span v-if="this.rangeType" class="p-ml-2">{{ periodHumanTitle }}</span>
                    </div>
                </div>
            </div>
            <div class="p-col-4 p-lg-2 p-mb-lg-0 p-py-0 p-d-flex radioWrapper">
                <div v-for="category of dayOfWeek" :key="category.key" class="p-field-checkbox">
                    <RadioButton
                        :disabled='!!periodicityId'
                        :id="category.key"
                        name="category"
                        :value="category"
                        v-model="v$.selectedDay.$model"
                    />
                    <label :for="category.key">{{ category.name }}</label>
                </div>
            </div>
            <div class="p-col-6 p-lg-2 p-mb-lg-0 p-pb-0 customRelatyve">
                <label for="calendarStart">Дата начала*</label>
                <Calendar
                    id="calendarStart"
                    class="p-mr-3 customWidth"
                    :disabled='!!periodicityId'
                    v-model="v$.dateStart.$model"
                    :showIcon="true"
                    dateFormat="dd.mm.yy"
                    :locale="rusCalendareLocale"
                    :disabledDays="disabledDays"
                    :disabledDates="disabledDates"
                    :manualInput="false"
                    :showTime="false"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    :class="v$.dateStart.$invalid && submitted ? 'p-error' : ''"
                />
                <small v-show="submitted" v-for="error in showErrors(v$.dateStart)" :key="error.$uid" class="p-error p-errorPosition p-errorPositionAdvanced">{{ error.$message }}</small>
            </div>
            <!-- {{ disabledDays }} -->
            <!-- {{ selectedDay }}-->
            <!-- {{ dateStart }} -->
            <!-- <div class="p-col-4 p-lg-2 p-mb-lg-0 p-py-0">
                <label class="p-text-normal p-col-12 p-px-0 p-pt-0" for="sort">Маска для именования периода</label>
                <InputText id="sort" style="width: 100%" v-model="v$.rangeMask.$model" type="text" :class="v$.rangeMask.$invalid && submitted ? 'p-error' : ''"> </InputText>
                <small v-show="submitted" v-for="error in showErrors(v$.rangeMask)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
            </div> -->
        </div>

        <div class="p-col-4 p-lg-4 p-mb-lg-0 p-d-flex p-jc-start p-px-0 p-pb-0">
            <div class="p-d-flex p-jc-start">
                <Button type="button" @click="sendPeriodData"> <span class="p-button-label">Сохранить </span> <span class="p-ink"></span> </Button>
                <Button class="p-button p-button-outlined p-ml-3" @click="skipChanges">
                    <span class="p-button-label black">Отменить</span>
                </Button>
            </div>
        </div>
        <Dialog :visible.sync="unsavedChangesShow" header="" position="center" :modal="true" :closeOnEscape="true" :closable="false">
            <div class="p-dialog-content p-ai-center p-d-flex">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
                <span>Вы вносили изменения, и не сохранили их. При уходе со старицы все не сохраненные данные будут утеряны.</span>
            </div>

            <template #footer>
                <Button class="p-button p-button-text p-button-danger" @click="abortChanges">
                    <span class="pi pi-times p-button-icon p-button-icon-left"></span>
                    <span class="p-button-label">Удалить данные и покинуть</span>
                </Button>
                <Button class="p-button p-component p-button-text" type="button" @click="unsavedChangesShow = false">
                    <span class="pi pi-check p-button-icon-left"></span>
                    <span class="p-button-label">Вернуться к заполнению</span>
                    <span class="p-ink"></span>
                </Button>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { getFormsPeriodById, patchFormsPeriod, registerNewFormsPeriod } from '@/api/form/formsPeriods';

import {minLength, required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {requestToastHandler} from '@/main/mixins';
import {rusCalendareLocale} from '@/main/calendareLocales';

export default {
    name: 'mainparametrs',
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        this.rusCalendareLocale = rusCalendareLocale;

        return {
            editDataLoading: false,
            periodTitle: '',
            rangeValue: null,
            rangeType: null,
            typesValues: [
                { name: 'Каждый день', code: 'day' },
                { name: 'Каждую неделю', code: 'week' },
                { name: 'Каждый месяц', code: 'month' },
                { name: 'Каждый год', code: 'year' },
            ],
            submitted: false,
            unsavedChangesShow: false,
            leaveRoute: null,
            dateStart: null,
            selectedDay: { name: 'Пн', key: 'monday', dayNumber: 1 },
            dayOfWeek: [
                { name: 'Пн', key: 'monday', dayNumber: 1 },
                { name: 'Вт', key: 'tuesday', dayNumber: 2 },
                { name: 'Ср', key: 'wednesday', dayNumber: 3 },
                { name: 'Чт', key: 'thursday', dayNumber: 4 },
                { name: 'Пт', key: 'friday', dayNumber: 5 },
                { name: 'Сб', key: 'saturday', dayNumber: 6 },
                { name: 'Вс', key: 'sunday', dayNumber: 0 },
                { name: 'Первый день месяца', key: 'first_day_of_month' },
            ],
            currentYear: null,
            minDate: null,
            maxDate: null
        };
    },
    validations() {
        const standart = (minLen = 5) => ({
            required: { ...required, $message: 'Поле обязательно к заполнению' },
            minLength: { ...minLength(minLen), $message: `Значение не должно быть короче ${minLen} символов` },
        });
        return {
            periodTitle: standart(3),
            rangeMask: {},
            rangeType: {
                name: { required: { ...required, $message: 'Поле обязательно к заполнению' } },
            },
            rangeValue: { required: { ...required, $message: 'Поле обязательно к заполнению' } },
            dateStart: { required: { ...required, $message: 'Поле обязательно к заполнению' } },
            selectedDay: {},
        };
    },
    methods: {
        monthChange($event) {
            console.log('monthChange($event)', $event);
        },
        async sendPeriodData() {
            this.submitted = true;
            if (this.formIsValid) {
                const currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60000;

                const dataToServer = {
                    data: {
                        type: 'form-distribution-interval',
                        id: null,
                        attributes: {
                            name: this.periodTitle,
                            counter: (!this.periodicityId && this.rangeValue) || undefined,
                            period: (!this.periodicityId && this.rangeType.code) || undefined,
                            active: true,
                            startDay: (!this.periodicityId && this.selectedDay.key) || undefined,
                            startDate: (!this.periodicityId && new Date(this.dateStart.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10)) || undefined,
                        },
                    },
                };
                // dataToServer;
                // registerNewFormsPeriod;
                this.$emit('loadingChange', true);
                try {
                    this.periodicityId ? await patchFormsPeriod(this.periodicityId, dataToServer) : await registerNewFormsPeriod(dataToServer);
                    // if (response)
                        // this.$toast.add({
                        //     severity: 'success',
                        //     summary: `Интервал успешно ${this.periodicityId ? 'отредактирован' : 'создан'}`,
                        //     life: '2500',
                        // });
                } catch (error) {
                    this.$emit('loadingChange', false);
                    this.$requestError(error.message);
                    throw new Error(error.message)
                    // return;
                }

                this.$emit('loadingChange', false);
                this.v$.$anyDirty = false;

                setTimeout(() => {
                    this.$router.push('/control/periodicity/list');
                }, 500);
            }
        },
        skipChanges() {
            this.$router.push('/control/periodicity/list');
            /*this.periodTitle = '';
            this.rangeValue = null;
            this.rangeType = null;*/
            this.submitted = false;
        },

        abortChanges() {
            this.v$.$anyDirty = false;
            this.$router.push(this.leaveRoute);
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        async getCurrentPeriod(periodicityId) {
            this.editDataLoading = true
            try {
                this.$emit('loadingChange', true);
                const result = await getFormsPeriodById(periodicityId);
                this.setCurrentPeriod(result.data);
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },
        setCurrentPeriod(period) {
            this.currentPeriod = period;
            this.periodTitle = this.currentPeriod.attributes.name;
            this.rangeType = this.typesValues.find((value) => value.code == this.currentPeriod.attributes.period);
            this.rangeValue = this.currentPeriod.attributes.counter;
            this.selectedDay = this.dayOfWeek.find(day => day.key === this.currentPeriod.attributes.startDay);
            this.dateStart = new Date(this.currentPeriod.attributes.startDate);
            this.currentYear = this.dateStart.getFullYear().toString();
            this.minDate = new Date(this.dateStart.getFullYear(), 0, 0);
            this.maxDate = new Date(this.dateStart.getFullYear() + 1, 0, 0);
            this.v$.$anyDirty = false;
        },
        correctNumerals(number, titles){
               let cases = [2, 0, 1, 1, 1, 2];
               return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
        },
    },
    computed: {
        // showWeekDays() {
        //     // console.log('this.rangeType.code == week', this.rangeType.code == 'week');
        //     if (this.rangeType) {
        //         return this.rangeType.code == 'week' ? true : false;
        //     }
        //     return false;
        // },
        disabledDates() {
            if (this.selectedDay.dayNumber === undefined) {
                let invalidDates = [];
                let today = new Date();
                let date = new Date(today.getFullYear(), 0, 1);
                let dayNumber = 2;
                let currentMonth = date.getMonth();

                /** Блокируем все дни года, кроме первого дня каждого месяца */
                while (date.getYear() === today.getYear()) {
                    if (date.getMonth() !== currentMonth) {
                        currentMonth = date.getMonth();
                    }
                    if (date.getDate() === 1) {
                        dayNumber = 2;
                        date.setDate(date.getDate() + 2);
                    } else {
                        date.setDate(date.getDate() + 1);
                    }
                    invalidDates.push(new Date(today.getFullYear(), currentMonth, dayNumber));
                    dayNumber++;
                }

                return invalidDates;
            }

            return [];
        },
        disabledDays() {
            if (this.selectedDay.dayNumber === undefined) {
                return null;
            }

            return [0, 1, 2, 3, 4, 5, 6].filter((day) => day !== this.selectedDay.dayNumber);
        },
        formIsValid() {
            return !this.v$.$invalid;
        },
        periodicityId() {
            return this.$route.params.periodicityId || null;
        },
        periodHumanTitle() {
            let
                titlesData = [],
                periodCount = this.rangeValue
            ;

            if(typeof( periodCount ) !== 'number' || periodCount < 0){
                periodCount = 1;
            }

            switch (this.rangeType.code) {
                case 'day':
                    titlesData = ['день', 'дня', 'дней'];
                    break;
                case 'week':
                    titlesData = ['неделя', 'недели', 'недель'];
                    break;
                case 'month':
                    titlesData = ['месяц', 'месяца', 'месяцев'];
                    break;
                case 'year':
                    titlesData = ['год', 'года', 'лет'];
                    break;
            }

            return this.correctNumerals(periodCount, titlesData);
        },
    },
    beforeRouteLeave(to, from, next) {
        this.leaveRoute = to.fullPath;
        console.log('LEAVE', this.v$);
        if (this.v$.$anyDirty && to.fullPath !== '/control/periodicity/new/periods') {
            // this.unsavedChangesShow = true;
            this.$root.$emit('show-leave-dialog', {
                acceptCb: async () => await this.sendPeriodData(),
                beforeClose: next
            })
            return;
        }
        next();
    },
    async created() {
        if (this.periodicityId) await this.getCurrentPeriod(this.periodicityId);
        this.$emit('loadingChange', false);
    },
    watch: {
        selectedDay: {
            handler() {
                if (!this.editDataLoading) {
                    this.dateStart = null
                } else {
                    this.editDataLoading = false
                }
            }
        }
    }
};
</script>

<style lang='scss' scoped>
::v-deep .p-datepicker {
    .p-timepicker {
        height: 0 !important;
        div {
            display: none;
        }
    }
}
.radioWrapper {
    div:nth-child(8) {
        label {
            width: 140px !important;
        }
    }
}
.customWidth {
    width: 265px !important;
}
label {
    display: block;
    padding-left: 0.1rem;
    padding-bottom: 4px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 14px;
}
.p-field-checkbox {
    label {
        padding-bottom: 0;
    }
}
.allHeight {
    flex: 1 1 auto;
    flex-grow: 1;
    flex-basis: 100%;
}
.customBGColor {
    background: #f8f9fa;
    border-radius: 4px;
}
.customRelatyve {
    position: relative;
}
.p-errorPosition {
    position: absolute;
    left: 0;
    top: 100%;
}
.p-errorPositionAdvanced {
    left: 15px;
}
::v-deep .inputCustomWidth {
    max-width: 25%;

    .p-inputtext {
        max-width: 100%;
    }
}
::v-deep .p-field-checkbox {
    margin-bottom: 0;
    align-items: center;
    label {
        margin-right: 19px;
    }
}
::v-deep .customRelatyve {
    .p-inputtext {
        padding: 8px 12px;
        font-size: 14px;
        line-height: 18px;
        color: #495058;
    }
}
::v-deep .customWidth {
    .p-inputtext {
        padding: 8px 12px;
        font-size: 14px;
        line-height: 18px;
        color: #495058;
    }
}
</style>
